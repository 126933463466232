var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"12"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',[_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("fields.holdername")))]),_c('inputvselect',{attrs:{"name":"holdername","label":function (reldata) { return reldata.name; },"valuex":_vm.appData.holdernameid,"keys":"key","options":_vm.familymembers,"id":"holdername","validations":"required"},on:{"updatedata":function (val) { return _vm.updatedetails('holdernameid', val); }}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"6"}},[_c('inputtext',{attrs:{"name":"instrumentname","tooltip":_vm.$t('fields.enter') + ' ' + _vm.$t('fields.instrumentname'),"label":_vm.$t('fields.instrumentname'),"placeholder":_vm.$t('fields.enter') + ' ' + _vm.$t('fields.instrumentname'),"valuex":_vm.appData.instrumentname,"validations":""},on:{"updatedata":function (val) { return (_vm.appData.instrumentname = val); }}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"6"}},[_c('inputtext',{attrs:{"name":"value","tooltip":_vm.$t('fields.enter') + ' ' + _vm.$t('fields.value'),"label":_vm.$t('fields.value'),"valuex":_vm.appData.marketvalue,"validations":'amount'},on:{"updatedata":function (val) { return _vm.managefloat('marketvalue', parseFloat(val)); }}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"6"}},[_c('inputtext',{attrs:{"name":"remarks","tooltip":_vm.$t('fields.enter') +
                      ' ' +
                      _vm.$t('fields.remarks'),"label":_vm.$t('fields.remarks'),"placeholder":_vm.$t('fields.enter') +
                      ' ' +
                      _vm.$t('fields.remarks'),"valuex":_vm.appData.remarks,"validations":""},on:{"updatedata":function (val) { return (_vm.appData.remarks = val); }}})],1),(_vm.edittype == 0)?_c('b-col',{attrs:{"sm":"12"}},[_c('h5',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("fields.fileinput")))]),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Upload File","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('fields.uploadfile'),"label-for":"fileinput"}},[_c('b-form-file',{attrs:{"accept":"image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf","id":"fileinput","name":"Upload File","placeholder":_vm.$t('fields.uploadfile'),"drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.updateValue($event)}}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"d-flex float-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"ArrowLeftIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("fields.back"))+" ")])],1),(_vm.edittype == 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","to":{
                        name: _vm.GENAPP_APP_STORE_MODULE_NAME + '-view',
                        params: { id: _vm.iddata },
                      }}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"EyeIcon"}}),_vm._v(" "+_vm._s(_vm.$t("fields.view"))+" ")],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary                            ","type":"submit"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"Edit3Icon"}}),_vm._v(" "+_vm._s(_vm.$t("fields.save"))+" ")],1)],1)])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }